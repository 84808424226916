<template>
  <div>
    <b-form-group :label-cols-sm="labelColsSm || 4" :label-cols-lg="labelColsLg || 3" label="Start date">
      <datepicker v-model="start" :state="state" :min="minStart" />
    </b-form-group>
    <b-form-group :label-cols-sm="labelColsSm || 4" :label-cols-lg="labelColsLg || 3" label="End range">
      <b-form-select v-model="predefinedRange" :state="state" :options="predefinedRangeOptions" @change="setEnd" />
    </b-form-group>
    <b-form-group :label-cols-sm="labelColsSm || 4" :label-cols-lg="labelColsLg || 3" label="End date">
      <datepicker v-model="end" :state="state" :min="minEnd" />
    </b-form-group>
  </div>
</template>

<script>
// TODO: remve time
import Datepicker from '../components/date-picker';
import * as moment from 'moment';

const TODAY = new Date();
const YESTERDAY = new Date();
const TOMORROW = new Date();
YESTERDAY.setDate(YESTERDAY.getDate() - 1);
TOMORROW.setDate(TOMORROW.getDate() + 1);

export default {
  components: {
    Datepicker,
  },
  props: ['value', 'pastDisabled', 'labelColsSm', 'labelColsLg', 'state'],
  data () {
    return {
      start: TODAY,
      predefinedRange: 'DAY',
      end: TOMORROW,
      minStart: undefined,
      minEnd: undefined,
      predefinedRangeOptions: [
        { value: null, text: 'Custom range' },
        { value: 'DAY', text: 'One day' },
        { value: 'WEEK', text: 'One Week' },
        { value: '1', text: 'One Month' },
        { value: '3', text: '3 Months' },
        { value: '6', text: '6 Months' },
        { value: '9', text: '9 Months' },
        { value: '12', text: '12 Months' },
        { value: '24', text: '24 Months' },
        { value: '36', text: '36 Months' },
      ],
    };
  },
  watch: {
    end () {
      this.emit();
    },
    start () {
      const start = moment(this.start);
      const end = moment(this.end);
      if (end.isSame(start) || end.isBefore(start)) {
        this.setEnd();
      }
      this.emit();
    },
    value () {
      this.checkValue(this.value);
    },
  },
  mounted () {
    if (this.value) {
      this.checkValue(this.value);
    }
    if (this.pastDisabled !== undefined) {
      this.min = TODAY;
    }
    this.emit();
  },
  methods: {
    checkValue (val) {
      if (val && (!moment(val.start).isSame(moment(this.start)) || !moment(val.end).isSame(moment(this.end)))) {
        this.start = val.start;
        this.end = val.end;
        this.emit();
      }
    },
    setEnd () {
      const { predefinedRange, start } = this;
      const future = new Date(start);
      const to = new Date(start);
      to.setDate(to.getDate() + 1);
      this.minEnd = to;
      if (predefinedRange === null || predefinedRange === 'DAY') {
        future.setDate(future.getDate() + 1);
      } else if (predefinedRange === 'WEEK') {
        future.setDate(future.getDate() + 7);
      } else {
        future.setMonth(future.getMonth() + parseInt(predefinedRange, 10));
      }
      this.end = future;
    },
    emit () {
      this.$emit('input', { start: this.start, end: this.end });
      this.changePre();
    },
    changePre () {
      const start = moment(this.start);
      const end = moment(this.end);
      const diffMonths = end.diff(start, 'months', true);
      const diffDays = end.diff(start, 'days', true);
      if (diffMonths >=1 && diffMonths <= 36 && diffMonths % 1 === 0) {
        this.predefinedRange = String(diffMonths);
      } else if (diffDays === 1 || diffDays === 7 ) {
        this.predefinedRange = diffDays === 1 ? 'DAY' : 'WEEK';
      } else {
        this.predefinedRange = null;
      }
    }
  },
};
</script>
