<template>
  <div class="row">
    <div class="col">
      <b-card title="Units">
        <blueprint-data
          api-route-path="bookings/units" :fields="fields" no-new no-actions no-init-fetch :where="where"
          :params="params" :q="q"
        >
          <template v-slot:search>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Properties">
              <blueprint-search-input
                id="properties"
                v-model="properties"
                :multiple="true"
                placeholder="Type to search Properties"
                label="name"
                track-by="id"
                api-route-path="inventory/properties"
              />
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Available" label-for="available">
              <b-form-select id="available" v-model="available">
                <option :value="null">
                  -
                </option>>
                <option :value="true">
                  Yes
                </option>>
              </b-form-select>
            </b-form-group>

            <date-range v-model="range" past-disabled />
          </template>
          <template v-slot:propertyId="{ item }">
            {{ item.property.name }}
          </template>
          <template v-slot:area="{ item }">
            {{ item.area }} m<sup>2</sup>
          </template>
          <template v-slot:type="{ item }">
            {{ item.type.name }}
          </template>
          <template v-slot:services="{ item }">
            <span v-if="item.included && item.included.services">
              {{ item.included.services.map(v => v.name).join(', ') }}
            </span>
          </template>
          <template v-slot:amenities="{ item }">
            <span v-if="item.included && item.included.amenities">
              {{ item.included.amenities.map(v => v.name).join(', ') }}
            </span>
          </template>
          <template v-slot:price="{ item }">
            {{ item.price?parseFloat(item.price).toFixed(2):0 }}/{{ item.priceMethod }}
          </template>
          <template v-slot:price_tax="{ item }">
            {{ parseFloat(parseFloat(item.price) + parseFloat(item.price) * parseFloat(item.tax)/100).toFixed(2) }}/{{ item.priceMethod }}
          </template>
          <template v-slot:available="{ item }">
            <b-badge v-if="item.available === true" variant="success">
              Available
            </b-badge>
            <b-badge v-if="item.available !== true" variant="danger">
              Not available
            </b-badge>
            &nbsp;<fa-icon :class="item.locked? 'text-danger' : 'text-black'" :icon="item.locked? 'lock' : 'lock-open'" />
          </template>
          <template v-slot:status="{ item }">
            <b-badge v-if="item.status === 'ENABLED'" variant="success">
              ENABLED
            </b-badge>
            <b-badge v-if="item.status !== 'ENABLED'" variant="danger">
              Out of Order
            </b-badge>
          </template>
          <template v-slot:bookactions="{ item }">
            <router-link
              v-if="item.available === true && item.status === 'ENABLED'"
              :to="{ name: 'bookings.units.book', params: { id: item.id }, query: { start: params.start ? params.start.toISOString() : undefined, end: params.end ? params.end.toISOString() : undefined } }"
            >
              <fa-icon icon="calendar-plus" /> Book a unit
            </router-link>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarPlus, faLock, faLockOpen,
} from '@fortawesome/free-solid-svg-icons';
import DateRange from '../../../components/date-range.vue';
import utils from '../../../libs/utils.vue';

library.add(faCalendarPlus, faLock, faLockOpen);

export default {
  components: {
    DateRange,
  },
  data () {
    return {
      where: {},
      params: {},
      range: undefined,
      properties: [],
      available: null,
      q: null,
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'area',
          sortable: true,
        },
        {
          key: 'propertyId',
          label: 'Property',
          sortable: true,
          slot: true,
        },
        {
          key: 'type',
          label: 'Type',
          sortable: false,
          slot: true,
        },
        {
          key: 'services',
          label: 'Services included',
          sortable: false,
          slot: true,
        },
        {
          key: 'amenities',
          label: 'Amenities included',
          sortable: false,
          slot: true,
        },
        {
          key: 'price',
          sortable: false,
          slot: true,
        },
        {
          key: 'price_tax',
          label: 'Price + Taxes',
          sortable: false,
          slot: true,
        },
        {
          key: 'status',
          sortable: true,
          slot: true,
        },
        {
          key: 'available',
          label: 'Availability',
          sortable: true,
          slot: true,
        },
        {
          key: 'bookactions',
          label: 'Actions',
          sortable: false,
          slot: true,
        },
      ],
    };
  },
  watch: {
    range () {
      this.params = {
        start: this.range.start,
        end: this.range.end,
      };
      this.$router.push({
        query:
          {
            start: this.params.start ? this.params.start.toISOString() : undefined,
            end: this.params.end ? this.params.end.toISOString() : undefined,
          },
      });
    },
    properties () {
      this.where = {
        ...utils.parseWhereItem('available', this.available),
        ...utils.parseWhereItem('propertyId', this.properties),
      };
    },
    available () {
      this.where = {
        ...utils.parseWhereItem('available', this.available),
        ...utils.parseWhereItem('propertyId', this.properties),
      };
    },
  },
  mounted () {
    const { start, end } = this.$route.query;
    if (start || end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      this.range = { start: startDate, end: endDate };
    }
  },
};
</script>
